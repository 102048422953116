

document.getElementById('dropbtn').onclick = function() {
  document.getElementById('dropdown-content').classList.toggle('active');
}





